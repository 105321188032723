/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { useEffect } from "react";
import { createContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuthToken, getUserDetails } from "./authentication";
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { ArraysHaveCommonItems, capitalizeObjectKeys } from "./Global";
import { CanAddActivity, setAddingActivity } from "./Lookup";
import ReactGA from "react-ga4";

export const Context = createContext();

const AppContext = ({ children }) => {
  const userId = getUserDetails()?.id;
  //console.log(getUserDetails())
  const CartObject = {
    id: 0,
    userId: userId ? userId : 0,
    cartId: "",
    orderType: 0,
    dateStarted: new Date(),
    dateSubmitted: null,
    dateComplete: null,
    numItems: 0,
    orderId: 0,
    amount: 0,
    deliveryFee: 0,
    vat: 0,
    promoCode: 0,
    useCredit: true,
    stockCheck: true,
    status: 0,
    deliveryAddressId: 0,
    instructions: "",
    statusMessage: "",
    paymentMessage: "",
    paymentMeta: "",
    deliveryMessage: "",
    deliveryMeta: "",
    cartItems: []
  }
  //const [categories, setCategories] = useState();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [banners, setBanners] = useState([]);
  const [orders, setOrders] = useState([]);
  const [oldItems, setOldItems] = useState([]);
  const [userAddress, setUserAddress] = useState([]);
  const [userAddresses, setUserAddresses] = useState([]);
  const [currentPromotions, setCurrentPromotions] = useState([]);
  const [heroData, setHeroData] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [promoCode, setPromoCode] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState(JSON.parse(localStorage.getItem('coupon')) || null);
  const [couponError, setCouponError] = useState('');
  const [adminAppliedCoupon, setAdminAppliedCoupon] = useState(null);
  const [adminCouponError, setAdminCouponError] = useState('');
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || CartObject);
  const [cartItems, setCartItems] = useState(CartObject.cartItems);
  const [cartCount, setCartCount] = useState(0);
  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [wishlistItems, setWishlistItems] = useState(JSON.parse(localStorage.getItem('wishlist')) || []);
  const [wishCount, setWishCount] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart'));
    if (storedCart) {
      setCartItems(storedCart.cartItems);
    }
  }, []);

  useEffect(() => {
    let count = 0;
    cartItems?.map((item) => (count += item.quantity));
    setCartCount(count);

    setWishCount(wishlistItems.length);

    let subTotal = 0;
    cartItems && cartItems.map(
      (item) =>
        (subTotal += item.price * item.quantity)
    );
    //const total = subTotal - discountAmount;
    setCartSubTotal(subTotal);
  }, [cartItems]);

  const applyDiscount = (subTotal, discountAmount) => {
    return (subTotal - discountAmount);
  };

  const getProducts = async () => {
    try {
      //const response = await fetch('api/products/getallproducts', {
      const response = await fetch('api/products/getbrandproducts/17', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setProducts(data);
        //console.log(data);
        return data;
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          console.log(response.status)
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getBanners= async () => {
    try { 
      //const response = await fetch('api//getallproducts', {
      const response = await fetch('api/razerbanners', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setBanners(data);
        //console.log(data);
        return data;
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          console.log(response.status)
      }

    } catch (e) {
      console.error(e);
    }
  }

  const AddProductActivity = async (ProductId, Type) => {
    try {
      if (CanAddActivity()) {
        setAddingActivity(1);

        const data = {
          ActivityBy: getUserDetails()?.id,
          ProductId,
          Type
        }

        const response = await fetch(`api/productactivities`, {
          method: "POST",
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })

        if (response.ok) {
          console.log("Product activity successfully added!");
          setTimeout(() => {
            setAddingActivity(0);
          }, 10000)
        }
        else {
          console.log(response.status + ":", response.statusText);
          setTimeout(() => {
            setAddingActivity(0);
          }, 10000)
        }

        setTimeout(() => {
          setAddingActivity(0);
        }, 10000)

      }
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        setAddingActivity(0);
      }, 10000)
    }
  }

  const getUserAddress = async (id) => {
    var bearer = 'Bearer ' + getAuthToken();
    const userId = getUserDetails()
    try {
      const response = await fetch('api/useraddress/getallforuser/' + userId.id, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data, userId.oldId, userId.id)
        //const newData = data.filter(item => userId.oldId && item.oldId == userId.oldId || item.userId == userId.id)
        const address = data.find(item => item.id == id)
        setUserAddress(address);
        //setUserAddresses(newData);
        setUserAddresses(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          console.log(response.status)
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getUserAddressAdmin = async (id) => {
    var bearer = 'Bearer ' + getAuthToken();
    const userId = getUserDetails()
    try {
      const response = await fetch('api/useraddress/' + id, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data, userId.oldId, userId.id)
        //const newData = data.filter(item => userId.oldId && item.oldId == userId.oldId || item.userId == userId.id)
        //const address = data.find(item => item.id == id)
        //setUserAddress(address);
        //setUserAddresses(newData);
        //setUserAddresses(data);
        return data
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          console.log(response.status)
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getUpcomingPromotions = async () => {
    let date = new Date().toISOString();
    try {
      const response = await fetch('api/promotions/getallupcoming/' + date, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setCurrentPromotions(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          console.log(response.status)
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getHomeBanners = async () => {
    try {
      const response = await fetch('api/banners/gethomebanners', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setHeroData(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          console.log(response.status)
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getAllOrders = async (customerId) => {
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/cart/getallorders/' + customerId, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        const data = await response.json();
        setOrders(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          navigate("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getOldCartItems = async (cartId, callback) => {
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/cartitems/getallolditems/' + cartId, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        setOldItems(data);
        if (typeof callback === 'function') {
          callback();
        }
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          navigate("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  const processPayment = async (cartId, addressId = 0, instructions) => {
    var bearer = 'Bearer ' + getAuthToken();
    if (appliedCoupon && appliedCoupon?.id) {
      cart.promoCode = appliedCoupon.id;
      await postToCart(cart);
    }
    if (!instructions) instructions = null;
    try {
      const response = await fetch(`api/cart/ProcessPayment/${cartId}/${getUserDetails()?.id}/${addressId}/${instructions}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        const data = await response.json();
        sendCustomEvent("Payment", "Initialising Payment", `${getUserDetails().emailAddress}`)
        ResetUserCart();
        window.location.href = data.redirect;
        //console.log("you did it too");
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          navigate("/login");
        else {
          ToastUtility.show({
            title: 'COMPLETE ORDER', content: 'There was an error completing your order. Please try again later!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
          });
        }
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getPromoCode = async () => {
    try {
      const response = await fetch('api/promocodes/getpromocode', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        const data = await response.json();
        setPromoCode(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          navigate("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getCartItems = async (cartId) => {
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/cartItems', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        let data = await response.json();
        let productData = await getProducts();
        const filteredCartItems = data.filter(item => item.cartId === cartId)
        if (filteredCartItems.length) {
          const updatedCartItems = filteredCartItems.map(cartItem => {
            const matchingProduct = productData.find(product => product.id === cartItem.productId);
            return {
              ...cartItem,
              product: matchingProduct,
              price: cartItem.totalCharged,
              quantity: cartItem.qty,
              img: cartItem.img,
            };
          });

          setCartItems(cart.cartItems ? [...cart.cartItems, ...updatedCartItems] : updatedCartItems);
          return cart.cartItems ? [...cart.cartItems, ...updatedCartItems] : updatedCartItems;
        }
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          navigate("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getCart = async (id) => {
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/cart/getusercart/' + id, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
      });
      if (response.ok) {
        let data = await response.json();
        if (data) {
          const newCartItems = await getCartItems(data.id)
          //console.log(newCartItems)
          setCart({ ...data, cartItems: newCartItems ? newCartItems : [] });

          return { ...data, cartItems: newCartItems ? newCartItems : [] }
        }
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          navigate("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  const UpdateCartDetails = async (info) => {

    const { cartItems, ...newCartObject } = info;


    //console.log("Ner:", cartItems, newCartObject);
    const bearer = "Bearer " + getAuthToken();
    try {
      const response = await fetch('api/cart/UpdateCart/' + getUserDetails().id, {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(capitalizeObjectKeys(newCartObject)),
      });

      if (response.ok) {
        const data = await response.json();

        setCart(JSON.stringify(data));
        window.localStorage.setItem("cart", JSON.stringify(data));

        if (data.id > 0)
          UpdateCartItems(data.id, cartItems);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const UpdateCartItems = async (CartId, CartItems) => {

    const bearer = "Bearer " + getAuthToken();
    const ItemObj = CartItems.map(item => capitalizeObjectKeys(item));
    try {
      const response = await fetch("api/cartitems/UpdateCartItems/" + CartId, {
        method: "PUT",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(ItemObj)
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setCartItems(data);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const postToCart = async (info) => {
    const { cartItems, ...newCartObject } = info;
    const cartItemsData = cartItems;

    //console.log(data, newCartObject)
    const updatedItems = [];
    const updatedCartItems = [];
    let currentCart;
    var bearer = 'Bearer ' + getAuthToken();

    
    try {
      const response = await fetch('api/cart', {
        method: newCartObject.id === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(capitalizeObjectKeys(newCartObject)),
      });

      if (response.ok) {
        const data = await response.json();
        if (newCartObject.id === 0) {
          const updatedCart = { ...newCartObject, id: data.item1, cartId: data.item2, cartItems };
          
          setCart(updatedCart);
          

          updatedCartItems.push(cartItemsData.map(item => ({
            ...item,
            cartId: data.item1,
            id: item.id ? item.id : 0,
          })))
          currentCart = { ...updatedCart };
          setCartItems(updatedCartItems);
          
          localStorage.setItem('cart', JSON.stringify({ ...updatedCart, cartItems: [...updatedCartItems.flat()] }));
        } else {
          if (newCartObject.cartId === "") {
            const updatedCart = { ...newCartObject, cartId: data.item2, cartItems };
            setCart(updatedCart);
            currentCart = { ...updatedCart };
            localStorage.setItem('cart', JSON.stringify(updatedCart));
          };
          
        }
        
        if (data.item1) {
          const localCartItems = JSON.parse(localStorage.getItem('cart')).cartItems.flat();
          for (let index = 0; index <= localCartItems.length - 1; index++) {
            const item = localCartItems[index];
            try {
              const responseCartItem = await fetch('api/cartItems', {
                method: item.id === 0 || !item.id ? 'POST' : 'PUT',
                withCredentials: true,
                credentials: 'include',
                headers: {
                  'Authorization': bearer,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  ...item,
                  cartId: data.item1,
                  id: item.id || 0,
                  BuyType: 0,
                  Qty: item.quantity,
                  PriceCharged: item.price,
                  TotalCharged: Math.abs(item.price * item.quantity),
                  Status: 0,
                  ProductImage: item.img,
                }),
              });

              if (responseCartItem.ok) {
                const responseData = await responseCartItem.json();
                const updatedItem = {
                  ...item,
                  id: responseData,
                };
                updatedItems.push(updatedItem);

                if (localCartItems.length - 1 === index) {
                  setCartItems(updatedItems);
                  let result = currentCart ? currentCart : newCartObject;
                  localStorage.setItem('cart', JSON.stringify({ ...result, cartItems: [...updatedItems] }));
                }
              } else {
                console.log(responseCartItem.status + ": " + responseCartItem.statusText);
                if (responseCartItem.status === 401) {
                  navigate("/login");
                }
              }
            } catch (error) {
              console.error(error);
              return item;
            }
          }

        }
      } else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401) {
          navigate("/login");
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  const adminPostToCart = async (info) => {
    const { cartItems, ...newCartObject } = info;
    const cartItemsData = cartItems;

    //console.log(data, newCartObject)
    const updatedItems = [];
    const updatedCartItems = [];
    let currentCart;
    var bearer = 'Bearer ' + getAuthToken();

    //console.log("Consol:", newCartObject, cartItemsData, ...cartItems);
    try {
      const response = await fetch('api/cart', {
        method: newCartObject.id === 0 ? 'POST' : 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(capitalizeObjectKeys(newCartObject)),
      });

      if (response.ok) {
        const data = await response.json();
        if (newCartObject.id === 0) {
          const updatedCart = { ...newCartObject, id: data.item1, cartId: data.item2, cartItems };
          //console.log("this one ",updatedCart, data)
          //setCart(updatedCart);
          //localStorage.setItem('cart', JSON.stringify(updatedCart));

          updatedCartItems.push(cartItemsData.map(item => ({
            ...item,
            cartId: data.item1,
            id: item.id ? item.id : 0,
          })))
          currentCart = { ...updatedCart };
          //setCartItems(updatedCartItems);
          //console.log(updatedCartItems, [...updatedCartItems.flat()])
          //localStorage.setItem('cart', JSON.stringify({ ...updatedCart, cartItems: [...updatedCartItems.flat()] }));
        } else {
          if (newCartObject.cartId === "") {
            const updatedCart = { ...newCartObject, cartId: data.item2, cartItems };
            //setCart(updatedCart);
            currentCart = { ...updatedCart };
            //localStorage.setItem('cart', JSON.stringify(updatedCart));
          } else {
            const updatedCart = { ...newCartObject, cartItems };

            currentCart = { ...updatedCart };
          }
        }
        //let newItem = cartItemsData[index];
        //console.log(data.item1)
        if (data.item1) {
          const localCartItems = currentCart.cartItems.flat();
          for (let index = 0; index <= localCartItems.length - 1; index++) {
            const item = localCartItems[index];
            try {
              const responseCartItem = await fetch('api/cartItems', {
                method: item.id === 0 || !item.id ? 'POST' : 'PUT',
                withCredentials: true,
                credentials: 'include',
                headers: {
                  'Authorization': bearer,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  ...item,
                  cartId: data.item1,
                  id: item.id || 0,
                  BuyType: 0,
                  Qty: item.quantity,
                  PriceCharged: item.price,
                  TotalCharged: Math.abs(item.price * item.quantity),
                  Status: 0,
                  ProductImage: item.img,
                }),
              });

              if (responseCartItem.ok) {
                const responseData = await responseCartItem.json();
                const updatedItem = {
                  ...item,
                  id: responseData,
                };
                updatedItems.push(updatedItem);

                if (localCartItems.length - 1 === index) {
                  //setCartItems(updatedItems);
                  //console.log(data, cart, currentCart, newCartObject)
                  let result = currentCart ? currentCart : newCartObject;
                  //localStorage.setItem('cart', JSON.stringify({ ...result, cartItems: [...updatedItems] }));
                }
              } else {
                console.log(responseCartItem.status + ": " + responseCartItem.statusText);
                if (responseCartItem.status === 401) {
                  navigate("/login");
                }
              }
            } catch (error) {
              console.error(error);
              return item;
            }
          }

        }
      } else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401) {
          navigate("/login");
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  //const postToWishlist = async (info) => {
  //    const { cartItems, ...newCartObject } = info;
  //    const cartItemsData = cartItems;

  //    //console.log(data, newCartObject)
  //    const updatedItems = [];
  //    const updatedCartItems = [];
  //    var bearer = 'Bearer ' + getAuthToken();

  //    console.log("Consol:", newCartObject);
  //    try {
  //        const response = await fetch('api/wishlist', {
  //            method: newCartObject.id === 0 ? 'POST' : 'PUT',
  //            withCredentials: true,
  //            credentials: 'include',
  //            headers: {
  //                'Authorization': bearer,
  //                'Content-Type': 'application/json'
  //            },
  //            body: JSON.stringify(capitalizeObjectKeys(newCartObject)),
  //        });

  //        if (response.ok) {
  //            const data = await response.json();
  //            if (newCartObject.id === 0) {
  //                const updatedCart = { ...newCartObject, id: data.item1, cartId: data.item2, cartItems };
  //                console.log(updatedCart)
  //                setCart(updatedCart);
  //                localStorage.setItem('cart', JSON.stringify(updatedCart));

  //                updatedCartItems.push(cartItemsData.map(item => ({
  //                    ...item,
  //                    cartId: data.item1,
  //                    id: item.id ? item.id : 0,
  //                })))
  //                //console.log(updatedCartItems)
  //                setCartItems(updatedCartItems);
  //                localStorage.setItem('cart', JSON.stringify({ ...cart, cartItems: updatedCartItems }));
  //            } else {
  //                if (newCartObject.cartId === "") {
  //                    const updatedCart = { ...newCartObject, cartId: data.item2, cartItems };
  //                    setCart(updatedCart);
  //                    localStorage.setItem('wishList', JSON.stringify(updatedCart));
  //                };
  //            }
  //            const localCartItems = JSON.parse(localStorage.getItem('wishList')).wishItems.flat();
  //            for (let index = 0; index <= localCartItems.length - 1; index++) {
  //                const item = localCartItems[index];
  //                try {
  //                    const responseCartItem = await fetch('api/wishlistitems', {
  //                        method: item.id === 0 || !item.id ? 'POST' : 'PUT',
  //                        withCredentials: true,
  //                        credentials: 'include',
  //                        headers: {
  //                            'Authorization': bearer,
  //                            'Content-Type': 'application/json'
  //                        },
  //                        body: JSON.stringify({
  //                            ...item,
  //                            wishListId: data.item1,
  //                            id: item.id || 0,
  //                            NumItems: item.quantity,
  //                            Status: 0,
  //                        }),
  //                    });

  //                    if (responseCartItem.ok) {
  //                        const responseData = await responseCartItem.json();
  //                        const updatedItem = {
  //                            ...item,
  //                            id: responseData,
  //                        };
  //                        updatedItems.push(updatedItem);

  //                        if (localCartItems.length - 1 === index) {
  //                            setCartItems(updatedItems);
  //                            localStorage.setItem('cart', JSON.stringify({ ...cart, cartItems: updatedItems }));
  //                        }
  //                    } else {
  //                        console.log(responseCartItem.status + ": " + responseCartItem.statusText);
  //                        if (responseCartItem.status === 401) {
  //                            navigate("/login");
  //                        }
  //                    }
  //                } catch (error) {
  //                    console.error(error);
  //                    return item;
  //                }
  //            }
  //        } else {
  //            console.log(response.status + ": " + response.statusText);
  //            if (response.status === 401) {
  //                navigate("/login");
  //            }
  //        }
  //    } catch (e) {
  //        console.error(e);
  //    }
  //}

  const deleteFromCart = async (data, remove, removeId = 0) => {
    const { cartItems, ...newCartObject } = data;
    var bearer = 'Bearer ' + getAuthToken();

    //console.log("Consol:", newCartObject);
    try {
      const response = await fetch('api/cart', {
        method: 'PUT',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newCartObject),
      });

      if (response.ok) {
        const data = await response.json();

        if (remove && removeId) {
          try {
            const response = await fetch('api/cartItems/' + removeId, {
              method: 'DELETE',
              withCredentials: true,
              credentials: 'include',
              headers: {
                'Authorization': bearer,
                'Content-Type': 'application/json'
              }
            });
            if (response.ok) {
              await response.json();
            }
            else {
              console.log(response.status + ": " + response.statusText);
              if (response.status === 401)
                navigate("/login");
            }
          } catch (e) {
            console.error(e);
          }
        }
      } else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401) {
          navigate("/login");
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  const CheckPaymentStatus = async () => {
    const bearer = "Bearer " + getAuthToken();
    try {
      const response = await fetch("api/cart/CheckPaymentStatus/" + getUserDetails()?.id, {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok)
        return "success";
      else
        return "failed";
    } catch (e) {
      console.error(e);
      return "failed";
    }
  }


  const ResetUserCart = () => {

    setCart(CartObject);
    localStorage.setItem('cart', JSON.stringify(CartObject));
    setCartItems([]);
  }

  const postNewAddress = async (data) => {
    const formData = {
      Address: data.address,
      Apartment: data.apartment || '',
      City: data.city || '',
      Company: data.company || '',
      Country: data.country || '',
      Id: 0,
      MobileNumber: data.mobileNumber || '',
      PostalCode: data.postalCode || '',
      Province: data.province || '',
      Suburb: data.suburb || '',

      DateAdded: new Date(),
      DateModified: new Date(),
      OldId: getUserDetails().oldId,
      Status: 1,
      UserId: getUserDetails().id,
    }

    var bearer = 'Bearer ' + getAuthToken();
    const response = await fetch('/api/useraddress', {
      method: formData.Id == 0 ? 'POST' : 'PUT',
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      const data = await response.json();
      ToastUtility.show({
        title: 'User Address', content: 'Address saved successfully', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
      });
      return data;
    } else {
      ToastUtility.show({
        title: 'User Address', content: 'There was an error saving address', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  };

  const getReviews = async (product) => {
    try {
      const response = await fetch('api/reviews/getallreviews/' + product, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setReviews(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          navigate("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getCategories = async () => {
    try {
      const response = await fetch('api/productcategories/getallcategories', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          navigate("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getSubcategories = async () => {
    try {
      const response = await fetch('api/productsubcategories/getallsubcategories', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setSubcategories(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          navigate("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  const getBrands = async () => {
    try {
      const response = await fetch('api/productbrands/getallbrands', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setBrands(data);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          navigate("/login");
      }

    } catch (e) {
      console.error(e);
    }
  }

  const GetFilterRelatedProducts = (ProductData, Products) => {
    let ProductTags = [], ProductCats = [], FilteredProducts = [];
    try {
      ProductTags = ProductData.tags !== "" ? ProductData.tags.replace(/\s/g, "").split(",") : [];
    } catch (e) { }

    try { ProductCats = JSON.parse(ProductData.selectedCategories); } catch (e) { }
    if (FilteredProducts.length < 4) {
      FilteredProducts = Products.filter(item => {
        let ItemTags = [], ItemCats = [];
        try {
          ItemCats = JSON.parse(item.selectedCategories);
        } catch (e) { }
        try {
          ItemTags = item.tags !== "" ? item.tags.replace(/\s/g, "").split(",") : [];
        } catch (e) { }

        return item.id !== ProductData.id && (ProductCats.length > 0 ? ArraysHaveCommonItems(ProductTags, ItemTags) && ArraysHaveCommonItems(ProductCats, ItemCats) : ArraysHaveCommonItems(ProductTags, ItemTags));
      })
    }

    if (FilteredProducts.length < 4) {
      products.filter(item => item.productBrandId === ProductData.productBrandId).map(item => FilteredProducts.push(item))
    }

    return FilteredProducts;
  }

  const getRandomObjectsFromArray = (arr, numObjects, productData) => {
    if (productData) {
      arr = GetFilterRelatedProducts(productData, arr);
    }

    if (numObjects >= arr.length) {
      return arr.slice();
    }

    const randomObjects = [];
    const usedIndices = new Set();

    while (randomObjects.length < numObjects) {
      const randomIndex = Math.floor(Math.random() * arr.length);

      if (!usedIndices.has(randomIndex)) {
        usedIndices.add(randomIndex);
        randomObjects.push(arr[randomIndex]);
      }
    }

    return randomObjects;
  }

  const handleAddToCart = (product, quantity) => {
    let items = [];

    if (cartItems)
      items = [...cartItems];

    let addedItem = items?.find((p) => p.productId == product?.productId);
    let index = items?.findIndex((p) => p.productId === product?.productId);
    if (index !== -1) {
      items[index].quantity += quantity;
    } else {
      product.quantity = quantity;
      items = [...items, product];
    }

    const updatedCart = { ...cart, cartItems: items };
    const updatedNumItems = items.reduce((total, item) => total + item.quantity, 0);
    const updatedAmount = items.reduce((total, item) => total + (item.price * item.quantity), 0);

    const update = {
      ...updatedCart,
      amount: updatedAmount,
      numItems: updatedNumItems
    };
    setCartItems(items);
    setCart(update);
    //setCart(prevCart => ({ ...prevCart, amount: updatedAmount, numItems: updatedNumItems }));
    localStorage.setItem('cart', JSON.stringify(update));
    ToastUtility.show({
      title: 'Cart',
      content: 'The item was successfully added to cart!',
      timeOut: 2000,
      position: { X: 'Right', Y: 'Top' },
      showCloseButton: true,
      cssClass: 'toast-success'
    });
    setCartCount(updatedNumItems)
    sendCustomEvent("Cart", "Added Item To Cart", `${product.title}`)
    if (getAuthToken()) {
      update.userId = getUserDetails().id;
      postToCart(update);
    }
    // localStorage.setItem('cart', JSON.stringify(updatedCart));
    // UpdateCartDetails(update);
  };

  const handleRemoveFromCart = (product) => {
    let items = [...cartItems];
    let removedItem = items?.find((p) => p.productId == product?.productId);
    items = items?.filter((p) => p.productId !== product?.productId);

    setCartItems(items);
    const updatedCart = { ...cart, cartItems: items };
    setCart(updatedCart);
    const updatedAmount = items.reduce((total, item) => total + (item.price * item.quantity), 0);
    const updatedNumItems = items.reduce((total, item) => total + item.quantity, 0);

    const update = {
      ...updatedCart,
      amount: updatedAmount,
      numItems: updatedNumItems
    };
    setCart(prevCart => ({ ...prevCart, amount: updatedAmount, numItems: cartCount }));

    setCartCount(updatedNumItems)
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    if (getAuthToken())
      deleteFromCart(update, true, removedItem.id);
    ToastUtility.show({
      title: 'Cart',
      content: 'The item was successfully removed from cart!',
      timeOut: 2000,
      position: { X: 'Right', Y: 'Top' },
      showCloseButton: true,
      cssClass: 'toast-success'
    });
    sendCustomEvent("Cart", "Removed Item From Cart", `${product.title}`)
  };

  const handleCartProductQuantity = (type, product) => {
    setCart(prevCart => {
      const updatedCart = { ...prevCart };
      const index = updatedCart.cartItems.findIndex(item => item.productId === product.productId);
      if (index !== -1) {
        if (type === "inc") {
          //updatedCart.cartItems[index].quantity += 1;
          if (updatedCart.cartItems[index].quantity < updatedCart.cartItems[index].product.stock)
            updatedCart.cartItems[index].quantity += 1;
          else
            updatedCart.cartItems[index].quantity = updatedCart.cartItems[index].product.stock;
        } else if (type === "dec" && updatedCart.cartItems[index].quantity > 1) {
          updatedCart.cartItems[index].quantity -= 1;
        }
        updatedCart.numItems = updatedCart.cartItems.reduce((total, item) => total + item.quantity, 0);
        updatedCart.amount = updatedCart.cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);
        setCartCount(updatedCart.numItems)
        localStorage.setItem('cart', JSON.stringify(updatedCart));
      }
      if (getAuthToken())
        postToCart(updatedCart);
      return updatedCart;
    });

    setCartItems(prevCartItems => {
      const updatedItems = prevCartItems.map(item => {
        if (item.productId === product.productId) {
          if (type === "inc") {
            item.quantity += 1;
          } else if (type === "dec" && item.quantity > 1) {
            item.quantity -= 1;
          }
        }
        return item;
      });
      return updatedItems;
    });
  };

  const applyCoupon = async (couponCode) => {
    var bearer = 'Bearer ' + getAuthToken();
    if (couponCode) {
      try {
        const response = await fetch(`api/promocodes/validatecoupon/${couponCode}/${getUserDetails()?.id}`, {
          method: 'GET',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAppliedCoupon(data);
          localStorage.setItem('coupon', JSON.stringify(data));
          const discount = applyDiscount(cartTotal, data.amount);
          sendCustomEvent("Coupon Code", "Applied Coupon code", `${couponCode}`)
          setDiscountAmount(discount);
          if (data.id > 0)
            setCouponError('');
          else
            setCouponError(data.message);
        } else {
          setAppliedCoupon(null);
          setCouponError('Invalid coupon code');
        }
      } catch (error) {
        console.error('Error applying coupon:', error);
        setCouponError('An error occurred while applying the coupon');
      }
    }
  };

  const applyAdminCoupon = async (couponCode) => {
    var bearer = 'Bearer ' + getAuthToken();
    if (couponCode) {
      try {
        const response = await fetch(`api/promocodes/validatecoupon/${couponCode}`, {
          method: 'GET',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAdminAppliedCoupon(data);
          if (data.id > 0)
            sendCustomEvent("Admin Coupon Code", "Admin applied Coupon code", `${couponCode}`);
          if (data.id > 0)
            setAdminCouponError('');
          else
            setAdminCouponError(data.message);
        } else {
          setAdminAppliedCoupon(null);
          setAdminCouponError('Invalid coupon code');
        }
      } catch (error) {
        console.error('Error applying coupon:', error);
        setAdminCouponError('An error occurred while applying the coupon');
      }
    }
  };

  const handleAddToWishlist = (item) => {

    if (wishlistItems.some((wishItem) => wishItem.productId === item.productId)) {
      return;
    }

    const updatedWishlist = [...wishlistItems, item];
    setWishlistItems(updatedWishlist);
    setWishCount(updatedWishlist.length);

    localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
    ToastUtility.show({
      title: 'Wish List',
      content: 'The item was successfully added to list!',
      timeOut: 2000,
      position: { X: 'Right', Y: 'Top' },
      showCloseButton: true,
      cssClass: 'toast-success'
    });

    sendCustomEvent("Wish List", "Added Item To Wish List", `${item.title}`)
  };

  const handleRemoveFromWishlist = (itemId) => {

    const updatedWishlist = wishlistItems.filter((item) => item.productId !== itemId);
    setWishlistItems(updatedWishlist);
    setWishCount(updatedWishlist.length);

    localStorage.setItem('wishlist', JSON.stringify(updatedWishlist));
    ToastUtility.show({
      title: 'Wish List',
      content: 'The item was successfully removed from list!',
      timeOut: 2000,
      position: { X: 'Right', Y: 'Top' },
      showCloseButton: true,
      cssClass: 'toast-success'
    });
  };

  const isItemInWishlist = (itemId) => {
    const storedWishlist = localStorage.getItem('wishlist');
    if (storedWishlist) {
      const wishlistItems = JSON.parse(storedWishlist);
      return wishlistItems.some((item) => item.productId === itemId);
    }
    return false;
  };

  const handleNavigate = (route) => {
    navigate(route);
  }

  const sendCustomEvent = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label, // optional
      //value: value, // optional, must be a number
      //nonInteraction: true, // optional, true/false
      //transport: 'xhr', // optional, beacon/xhr/image
    });
  }

  return (
    <Context.Provider
      value={{
        products,
        setProducts,
        getProducts,
        AddProductActivity,
        categories,
        setCategories,
        getCategories,
        subcategories,
        setSubcategories,
        getSubcategories,
        brands,
        setBrands,
        getBrands,
        cart,
        getCart,
        postToCart,
        deleteFromCart,
        adminPostToCart,
        cartItems,
        setCartItems,
        handleAddToCart,
        cartCount,
        wishCount,
        handleRemoveFromCart,
        showCart,
        setShowCart,
        handleCartProductQuantity,
        cartSubTotal,
        cartTotal,
        wishlistItems,
        setWishlistItems,
        handleAddToWishlist,
        handleRemoveFromWishlist,
        isItemInWishlist,
        getRandomObjectsFromArray,
        handleNavigate,
        getAllOrders,
        setOrders,
        orders,
        getUserAddressAdmin,
        getUserAddress,
        setUserAddress,
        userAddress,
        userAddresses,
        getReviews,
        setReviews,
        reviews,
        applyAdminCoupon,
        adminCouponError,
        adminAppliedCoupon,
        setAdminAppliedCoupon,
        setAdminCouponError,
        applyCoupon,
        couponError,
        appliedCoupon,
        postNewAddress,
        currentPromotions,
        setCurrentPromotions,
        getUpcomingPromotions,
        heroData,
        setHeroData,
        getHomeBanners,
        getPromoCode,
        setPromoCode,
        promoCode,
        processPayment,
        getOldCartItems,
        setOldItems,
        oldItems,
        CheckPaymentStatus,
        ResetUserCart,
        sendCustomEvent,
        banners,
        getBanners
        //googleLogin,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;