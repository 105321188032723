import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
//import './custom.css';
import './css/app.css';
import './helpers/NumberExtension';
import './helpers/string-prototypes';

export default class App extends Component {
  static displayName = App.name;


  RedirectToPdf = () => {
    React.useEffect(() => {
      const pdfUrl = "https://www.razershop.co.za/downloads/razer-shop-e-catalogue-1.pdf";
      //window.open(pdfUrl, "_self");
      window.location.href = pdfUrl;
    }, []);

    return <p>Loading Catalogue...</p>;
  };

  render() {
    return (
      <Layout>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;
            return <Route key={index} {...rest} element={element} />;
          })}
          <Route
            path="/ragecatalogue"
            element={<this.RedirectToPdf />}
            caseSensitive={false}
          />
        </Routes>
      </Layout>
    );
  }
}
