import moment from "moment"
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver';

export const toIsoDateTime = (value) => {
  if (value && value !== "") {
    let converted;
    if (value.match(/(\d\d)\/(\d\d)\/(\d\d\d\d) (\d\d):(\d\d)/g))
      converted = moment(value, "DD/MM/YYYY HH:mm").format('YYYY-MM-DDTHH:mm:ssZ');
    else
      converted = moment(value, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DDTHH:mm:ssZ");
    return converted;
  }
  else return "";
}


export const calculateSavingsPercentage = (originalPrice, discountedPrice) => {
  const savingsAmount = originalPrice - discountedPrice;
  const savingsPercentage = (savingsAmount / originalPrice) * 100;
  return Math.abs(savingsPercentage).toFixed(0);
};

export const ChangeFormat = (value, format) => {
  if (!value || value === "")
    return "";
  else
    return moment(value).format(format);
}

export const getImage = (Content) => {
  return "data:image/png;base64," + Content;
}

export const ArraysHaveCommonItems = (Arr1, Arr2) => {
  const CommonItems = Arr1.filter(item => Arr2.includes(item));
  return CommonItems.length > 0;
}

export const capitalizeObjectKeys = (obj) => {
  const newObj = {};

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
      newObj[capitalizedKey] = obj[key];
    }
  }

  return newObj;
}

export function handleExportClick(title, subcategories, brands) {

  let newSub = subcategories.map(item => (item.title + " - " + item.id)).sort((a, b) => {
    if (a > b) {
      return 1
    } else {
      return -1
    }
  });
  let newBrands = brands.map(item => (item.title + " - " + item.id)).sort((a, b) => {
    if (a > b) {
      return 1
    } else {
      return -1
    }
  });
  //console.log(newSub, newBrands)
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Sheet1')
  worksheet.columns = [
    { header: 'Title', key: 'Title', width: 40 },
    { header: 'Slug', key: 'Slug', width: 20 },
    { header: 'SKU', key: 'SKU', width: 20 },
    { header: 'Brand', key: 'Brand', width: 20 },
    { header: 'GTIN', key: 'GTIN', width: 20 },
    { header: 'Subcategories', key: 'Subcategories', width: 20 },
    { header: 'ShortDescription', key: 'ShortDescription', width: 30 },
    { header: 'Weight', key: 'Weight', width: 10 },
    { header: 'Height', key: 'Height', width: 10 },
    { header: 'Length', key: 'Length', width: 10 },
    { header: 'Width', key: 'Width', width: 10 },
    { header: 'Price', key: 'Price', width: 15 },
    { header: 'Stock', key: 'Stock', width: 15 },
    { header: 'StockCount', key: 'StockCount', width: 15 },
    { header: 'LowStockAmount', key: 'LowStockAmount', width: 15 },
    { header: 'SPrice', key: 'SPrice', width: 15 },
    { header: 'DateSPriceStarts', key: 'DateSPriceStarts', width: 15 },
    { header: 'DateSPriceEnds', key: 'DateSPriceEnds', width: 15 },
    { header: 'OutOfStock', key: 'OutOfStock', width: 15 },
    { header: 'About', key: 'About', width: 30 },
    { header: 'AdditionalInfo', key: 'AdditionalInfo', width: 30 },
    { header: 'Images', key: 'Images', width: 50 },
    { header: 'Tags', key: 'Tags', width: 100 },
  ];

  for (let i = 2; i <= 500; i++) {
    worksheet.getCell(`D${i}`).dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: [`=Lists!$A$2:$A$${newBrands.length + 1}`]
    }

    worksheet.getCell(`F${i}`).dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: [`=Lists!$B$2:$B$${newSub.length + 1}`]
    }

  };

  const listsWorksheet = workbook.addWorksheet('Lists');
  listsWorksheet.columns = [
    { header: 'Brand Name', key: 'BrandName', width: 20 },
    { header: 'Subcategory Name', key: 'SubcategoryName', width: 20 },
  ];
  const arr = []
  if (newBrands.length > newSub.length) {
    for (let i = 0; i <= newBrands.length; i++) {
      arr.push({ BrandName: newBrands[i], SubcategoryName: newSub[i] ? newSub[i] : null })
    }
  } else {
    for (let i = 0; i <= newSub.length; i++) {
      arr.push({ BrandName: newBrands[i] ? newBrands[i] : null, SubcategoryName: newSub[i] })
    }
  }

  arr.forEach(item => {
    listsWorksheet.addRow(item);
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, `${title} (${new Date().toISOString().split("T")[0]}).xlsx`);

  })
}

export function exportProducts(title, data) {
  //console.log(newSub, newBrands)
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Products')
  worksheet.columns = [
    { header: 'Title', key: 'title', width: 40 },
    { header: 'Slug', key: 'slug', width: 20 },
    { header: 'SKU', key: 'sku', width: 20 },
    { header: 'Brand', key: 'productBrandId', width: 20 },
    { header: 'GTIN', key: 'gtin', width: 20 },
    { header: 'Subcategories', key: 'selectedCategories', width: 20 },
    { header: 'ShortDescription', key: 'shortDescription', width: 30 },
    { header: 'Weight', key: 'weight', width: 10 },
    { header: 'Height', key: 'height', width: 10 },
    { header: 'Length', key: 'length', width: 10 },
    { header: 'Width', key: 'width', width: 10 },
    { header: 'Price', key: 'price', width: 15 },
    { header: 'Stock', key: 'stock', width: 15 },
    { header: 'StockCount', key: 'stockCount', width: 15 },
    { header: 'LowStockAmount', key: 'lowStockAmount', width: 15 },
    { header: 'SPrice', key: 'sPrice', width: 15 },
    { header: 'DateSPriceStarts', key: 'dateSPriceStarts', width: 15 },
    { header: 'DateSPriceEnds', key: 'dateSPriceEnds', width: 15 },
    { header: 'OutOfStock', key: 'outOfStock', width: 15 },
    { header: 'About', key: 'about', width: 30 },
    { header: 'AdditionalInfo', key: 'additionalInfo', width: 30 },
    { header: 'Images', key: 'images', width: 50 },
    { header: 'Tags', key: 'tags', width: 100 },
    { header: 'Status', key: 'status', width: 20 },
    { header: 'Reviews', key: 'reviews', width: 30 },
    { header: 'AvgRating', key: 'avgRating', width: 30 },
    { header: 'AddedBy', key: 'addedBy', width: 30 },
    { header: 'ModifiedBy', key: 'modifiedBy', width: 30 },
    { header: 'DateModified', key: 'dateAdded', width: 50 },
    { header: 'DateModified', key: 'dateModified', width: 50 },
  ];

  worksheet.addRows(data)

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, `${title} (${new Date().toISOString().split("T")[0]}).xlsx`);

  })
}