/* eslint-disable no-extend-native */
String.prototype.ChangeDateFormat = function(format){

}

String.prototype.toIsoDateTime = function(){
    console.log(this)

    if(this === null || this === "")
    return "";
    else {
        
    }
}

String.prototype.isNullOrWhitespace = function () {
  return this === null || this === undefined || this.trim() === '';
};
